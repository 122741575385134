<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Phân quyền  </a></li>
    </ol>
    <div class="container-fluid">
      <div class="col-sm-10">
        <div class="form-group">
          <router-link to="/admin/roles/add" class="btn btn-sm btn-primary">
            <i class="fas fa-plus"></i>
            Thêm mới 
          </router-link>
        </div>
      </div>

      <div>
        <div>
            
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <Skeleton width="40px" height="40"></Skeleton>
              </SkeletonTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify" v-if="mydata != null"></i> Quản lý
              : ( {{ mydata.length }} )
            </div>
            <div class="card-block">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      
                      <th>Tên </th>
                       <th>Phòng ban </th>
                      <th>Phân Quyền </th>
                      <th>Phân Quyền Chức năng  </th>
                      <th>Tuỳ chọn</th>
                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 10" :key="k">
                      <td>
                        <Skeleton width="40px" height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>

                    
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                        <br />

                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(value, key) in paginationslice(mydata, 10, page)"
                      :Key="key"
                    >
                    
                   
                      <td>
                        {{ value.name }}
                      </td>
                      <td>
                            {{value.departmentId | View_Department(departments) }}
                      </td>
                      <td :inner-html.prop="value.permissions | View_Permission(cate)">
                          
                      </td>

                     <!--  <td  :inner-html.prop="value.flagIndex | View_flagIndex()">
                          
                      </td> -->

                       <td  :inner-html.prop="value.flagContent | View_flagContent()">
                          
                      </td>
                      
                      <td v-if="id_load !== value.id">
                        <a
                          class="btn btn-sm btn-success"
                         
                        :href="'/admin/roles/edit/' + value.id"
                        >
                          <i class="fa fa-edit"></i>
                          Sửa
                        </a>
                        <div
                          class="btn btn-sm btn-danger click_remove"
                          @click="removeElement(value.id)"
                         
                        >
                          <i class="fa fa-remove"></i> Xoá
                        </div>
                      </td>
                      <td v-else>
                        <h4>Loading...</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <nav v-if="mydata != null && mydata.length > 10">
                  <VuePagination
                    :page-count="Math.ceil(mydata.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </SkeletonTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";


import { route_admin } from "../../../../router/route_admin";


import axios from "axios";
export default {
  name: "Roles.List",
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      cate:{
        parent:null,
        child:null

      },
      departments:null,
      search: "",
      page: 1,
      id_load: "",
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
  },
  filters:{
    View_Department(e,departments){
         if(e==null){
          return '';
         }
       let val=departments.filter(v=>v.id==e);

       if(val.length>0){
          return val[0].name;
       }
       
       return '';
    },
    View_Permission(e,cate){
       if(e==null){
        return '';
       }
       let result=[];
       let my_per=e.split(",");
       for (var i = cate.parent.length - 1; i >= 0; i--) {
          if(my_per.indexOf(cate.parent[i].id)>-1){
             result.push("<p> " +" - "+cate.parent[i].name+"</p>");
          }
       }

       return result.join(" ");



    },
    View_flagIndex(e){
       if(e==null){
        return '';
       }
       let result=[];
       let my_per=e.split(",");
       
        for (var i = my_per.length - 1; i >= 0; i--) {
            if(my_per[i]=='1'){
                result.push("<p> " +" - Thuê Mặt bằng "+"</p>");
            }
             if(my_per[i]=='2'){
                result.push("<p> " +" - Thuê Quầy vé"+"</p>");
            }
        }
       return result.join(" ");

    },
    View_flagContent(e){
       if(e==null){
        return '';
       }
       let result=[];
       let my_per=e.split(",");
        
        let func=[];

        for(var x=0;x<route_admin.length-1;x++){
            if( route_admin[x].label!=undefined){
                func.push({
                    id: route_admin[x].name,
                    name:route_admin[x].label
                });
            }
        }


        for (var i = my_per.length - 1; i >= 0; i--) {
           
            for(var j=func.length-1;j>=0;j--) {
               if(my_per[i]==func[j].id){
                result.push("<p> " +func[j].name+"</p>");
               }
            }
             
        }


       return result.join(" ");

    }
  },

  mounted() {
   let p1=  axios
      .get(window.my_api + "api/role/get-roles", {
        headers: {
          language: this.$i18n.locale,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.mydata = res.data.data.filter(v=>v.language=="VN");
         
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

    let p2=  axios
      .get(window.my_api + "api/newscate/get-newscates", {
        headers: {
          language: this.$i18n.locale,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.cate.parent = res.data.data;
          this.cate.child = res.data.data.filter(v=>v.parentId!=null);
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

    let p3=  axios
      .get(window.my_api + "api/department/get-departments", {
        headers: {
          language: this.$i18n.locale,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.departments = res.data.data;
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

      Promise.all([p1,p2,p3]).then(()=>{
          this.is_loading=false;
      });

  },
  methods: {
    getChild(id) {
      if (this.mydata == null) {
        return new Array();
      }
      var result = this.mydata.filter((e) => e.parentId == id);

      result.sort((a, b) => a.orderBy - b.orderBy);

      return result;
    },
    changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    
    removeElement(id) {
    if(confirm("Bạn muốn xoá?")){
            this.id_load = id;
            axios
              .delete(window.my_api + "api/role/remove-role", {
                data: { roleIds: [id] },
              })
              .then((res) => {
                if (res.status == 200) {
                  alert("Xoá thành công");
                  console.log("FIRST",this.mydata);
                  this.mydata = this.mydata.filter((el) => el.id != this.id_load );
                  console.log("OK",this.mydata);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
        
    },
  },
};
</script>








